import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Glide from '@glidejs/glide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import TextLink from '../common/text-link'
import ButtonLink from '../common/button-link'
import CarouselGlideArrowsSquare from '../common/carousel-glide-arrows-square'

const FeaturedCarousel = (props) => {
  const data = useStaticQuery(graphql`
    query {
      slides: allWpFeaturedContent(
        filter: {status: {eq: "publish"}}
      ) {
        nodes {
          featuredContentFields {
            cta {
              linkType
              externalUrl
              buttonText
              relativeUrl
            }
            text
          }
          title
          status
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 1600, aspectRatio: 1.33, transformOptions: {fit: COVER, cropFocus: CENTER})
                }
              }
            }
          }
        }
      }
    }
  `)

  const numImages = data.slides.nodes.length
  let numImagesLoaded = 0
  const handleImagesLoaded = () => {
    numImagesLoaded++
    if (numImages === numImagesLoaded) {
      slider.update()
    }
  }

  const paddingXClasses = 'px-1 px-md-2 px-lg-3 px-xl-4 px-xxl-5'

  const slider = new Glide('.glide-featured-carousel', {
    type: 'carousel',
    // autoplay: 5000,
    autoplay: false,
    gap: 0,
  })

  useEffect(() => {
    slider.mount()
  }, [])

  return (
    <section className="bg-primary">
      <Container fluid className="glide glide-featured-carousel px-0">
        <div className="glide__track position-relative" data-glide-el="track">
          <div className="glide__slides">
            {data.slides.nodes.map((node, index) => {
              const target = node.featuredContentFields.cta.linkType !== 'internal'
              const link = node.featuredContentFields.cta.linkType === 'internal' ? node.featuredContentFields.cta.relativeUrl : node.featuredContentFields.cta.externalUrl
              return (
                <div className="glide__slide" key={`carousel-${index}`}>
                  <div className="d-sm-flex h-100">
                    <GatsbyImage
                      image={getImage(node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData)}
                      alt={node.featuredImage?.node?.altText}
                      onLoad={handleImagesLoaded}
                      className="order-sm-2"
                    />
                    <div className={`pt-2 pb-5 pt-md-3 pt-md-3 pt-xxl-5 ${paddingXClasses} flex-xl-fill text-xl-center d-xl-flex flex-column align-items-center justify-content-center`}>
                      <div className="glide-featured-carousel__text">
                        <h2 className="mb-1 fw-bold">
                          <TextLink btnText={node.title} btnLink={link} newWindow={target} btnClass="text-white" />
                        </h2>
                        {node.featuredContentFields.text && (
                        <div className="text-white" dangerouslySetInnerHTML={{ __html: node.featuredContentFields.text }} />
                        )}
                        <ButtonLink btnText={node.featuredContentFields.cta.buttonText} btnLink={link} newWindow={target} btnClass="btn btn-light btn-lg mt-1 text-primary" />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <CarouselGlideArrowsSquare />
        </div>
      </Container>
    </section>
  )
}

export default FeaturedCarousel
