import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import Container from 'react-bootstrap/Container'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'
// import { useMediaQuery } from 'usehooks-ts'
import RTBLines from '../gsap/svg/rtb-lines.inline.svg'
import useBetterMediaQuery from '../hooks/use-better-media-query'

const rtbFacts = [
  {
    item: 'Complete protein',
    percent: 33,
  },
  {
    item: 'Fiber',
    percent: 25,
  },
  {
    item: 'B9/folate',
    percent: 35,
  },
  {
    item: 'Zinc',
    percent: 45,
  },
]

const RTBs = () => {
  const el = useRef(null)
  const q = gsap.utils.selector(el)
  // const [isMobile, setIsMobile] = useState(false)

  const isMobile = useBetterMediaQuery('(max-width: 767px)')

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

    const mm = gsap.matchMedia()
    mm.add('(min-width: 768px)', () => {
      const facts = gsap.utils.toArray('.rtb-fact')
      const masks = gsap.utils.toArray(q('.rtb-line-mask path'))
      gsap.set(q('.rtb-line-mask path'), { drawSVG: 0 })
      gsap.set('.rtb-fact', { opacity: 0, scale: 0.8 })

      const tl = gsap.timeline({
        defaults: {
          duration: 0.5,
        },
        scrollTrigger: {
          start: 'top 75%',
          trigger: '.rtbs',
        },
      })

      masks.forEach((mask, i) => {
        tl
          .to(masks[i], { drawSVG: true, ease: 'Power2.easeInOut' }, '-=0.2')
          .to(facts[i], { opacity: 1, scale: 1, ease: 'back.out(1.7)' })
      })
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }
  }, [])

  /*
  useEffect(() => {
    setIsMobile(window.innerWidth < 768)
  }, [])
  */

  return (
    <section id="RTBs" className="bg-primary pb-2 py-md-3 py-xl-4">
      { isMobile
        && (
        <StaticImage
          src="../../images/rtbs/classic-cutlet-mobile.png"
          quality={95}
          alt="Meati Classic Cutlet"
          layout="constrained"
          className="me-2 me-sm-3 mb-1"
          width={645}
          placeholder="blurred"
        />
        )}
      <Container className="d-flex flex-column align-items-center">

        <h2 className="mb-1 mb-xl-0 text-white text-center">Excellent — That Means the Very Best! — Source of… </h2>
        <div className="rtbs position-relative w-100">

          { !isMobile
            && (
            <>
              <div className="rtbs--bg-image position-absolute">
                <StaticImage
                  src="../../images/rtbs/classic-cutlet.png"
                  quality={95}
                  alt="Meati Classic Cutlet"
                  layout="constrained"
                  className="me-2 me-sm-3 mb-1"
                  width={1320}
                  placeholder="blurred"
                />
              </div>
              <div className="rtbs--lines position-absolute h-100 w-100 top-0 left-0" ref={el}>
                <RTBLines />
              </div>
            </>
            )}
          <div className="rtbs--facts text-white mb-1 mb-md-0 position-relative text-center">
            {
              rtbFacts.map((node, index) => (
                <div className="rtb-fact rounded-4 border border-white border-2 p-1 my-12 my-md-0 lh-sm" key={index}>
                  <h5>{node.item}</h5>
                  <span>
                    {node.percent}
                    % DV
                    <sup>*</sup>
                  </span>
                </div>
              ))
            }
          </div>
          <div className="text-white mb-1 position-relative text-center">
            <small>
              <sup>*</sup>
              Recommended Daily Value per Serving of Meati’s Classic Cutlet
            </small>
          </div>
        </div>
        <ButtonLink btnText="See How It's Made" btnLink="/what-is-meati" btnClass="btn-lg btn-light mt-12 mt-md-3" />
      </Container>
    </section>
  )
}

export default RTBs
