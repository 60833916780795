import * as React from 'react'
import useBetterMediaQuery from '../hooks/use-better-media-query'

const CarouselGlideArrowsSquare = (props) => {
  const isMobile = useBetterMediaQuery('(max-width: 1199px)')
  let arrowColor = props.arrowColor || 'primary'
  let bgColor = props.bgColor || 'white'
  if (isMobile) {
    [arrowColor, bgColor] = [bgColor, arrowColor]
  }

  return (
    <div className="glide__arrows glide__arrows--square d-flex" data-glide-el="controls">
      <button className={`glide__arrow glide__arrow--left text-${arrowColor}`} data-glide-dir="<">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 448 448" className={`rounded-2 bg-${bgColor}`}>
          <path fill="currentColor" d="M16,384c0,26.5,21.5,48,48,48h320c26.5,0,48-21.5,48-48V64c0-26.5-21.5-48-48-48H64c-26.5,0-48,21.5-48,48V384z M64,448 c-35.3,0-64-28.7-64-64V64C0,28.7,28.7,0,64,0h320c35.3,0,64,28.7,64,64v320c0,35.3-28.7,64-64,64H64z M98.3,218.3l104-104 c3.1-3.1,8.2-3.1,11.3,0s3.1,8.2,0,11.3L123.3,216H344c4.4,0,8,3.6,8,8s-3.6,8-8,8H123.3l90.3,90.3c3.1,3.1,3.1,8.2,0,11.3 s-8.2,3.1-11.3,0l-104-104C95.2,226.5,95.2,221.4,98.3,218.3L98.3,218.3z" />
        </svg>
      </button>
      <button className={`glide__arrow glide__arrow--right text-${arrowColor}`} data-glide-dir=">">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 448 448" className={`rounded-2 bg-${bgColor}`}>
          <path fill="currentColor" d="M432,64c0-26.5-21.5-48-48-48H64c-26.5,0-48,21.5-48,48v320c0,26.5,21.5,48,48,48h320c26.5,0,48-21.5,48-48V64z M384,0 c35.3,0,64,28.7,64,64v320c0,35.3-28.7,64-64,64H64c-35.3,0-64-28.7-64-64V64C0,28.7,28.7,0,64,0H384z M349.7,229.7l-104,104 c-3.1,3.1-8.2,3.1-11.3,0s-3.1-8.2,0-11.3l90.3-90.4H104c-4.4,0-8-3.6-8-8s3.6-8,8-8h220.7l-90.3-90.3c-3.1-3.1-3.1-8.2,0-11.3 s8.2-3.1,11.3,0l104,104C352.8,221.5,352.8,226.6,349.7,229.7L349.7,229.7z" />
        </svg>
      </button>
    </div>
  )
}

export default CarouselGlideArrowsSquare
