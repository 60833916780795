import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'

const CookingTipsTiles = (props) => {
  const tips = useStaticQuery(graphql`
    query {
      allCookingTipsYaml {
        nodes {
          tip_number
          title
          text
          bg_class
          image_cropped {
            childImageSharp {
              gatsbyImageData(
                width: 547
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  return (
    <section id="cookingTipsTiles" className="bg-light py-2 py-md-3 py-xl-4">
      <Container>
        <h2 className="mb-1 mb-md-3 text-center text-primary">For Your Best meati Ever</h2>
        <Row className="g-1">
          {
            tips.allCookingTipsYaml.nodes.map((node, index) => {
              const textClass = node.bg_class === 'bg-warning' ? '' : 'text-white'
              return (
                <Col md="4" className="tip-column my-12" key={`tip-${index}`}>
                  <div className={`tip-tile d-flex d-md-block align-items-center p-12 p-md-1 h-100 rounded-4 text-md-center position-relative ${node.bg_class} ${textClass}`}>
                    <GatsbyImage
                      image={getImage(node.image_cropped)}
                      alt={`meati ${node.title} illustration`}
                      className="rounded-4 me-1 me-md-0 mb-md-1"
                      imgClassName="rounded-4"
                    />
                    <div className="p-lg-1">
                      <h3>{node.title}</h3>
                      <p className="subtitle mb-0">{node.text}</p>
                    </div>
                    <div className="cooking-tip-label bg-white rounded text-uppercase text-primary py-12 px-1 fw-bold position-absolute">
                      Tip
                      {' '}
                      {node.tip_number}
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
        <div className="mt-1 mt-lg-2 d-flex justify-content-center">
          <ButtonLink btnText="Learn to Cook" btnLink="/kitchen" />
        </div>
      </Container>
    </section>
  )
}

export default CookingTipsTiles
