import * as React from 'react'
import { Link } from 'gatsby'

const TextLink = (props) => {
  return (
    <span>
      { props.newWindow === 'true'

        ? (
          <a
            className={[props.btnClass, 'ad-el-tracked'].join(' ')}
            data-ad-element-type="headline link"
            data-ad-text={props.btnText}
            data-ad-button-id="version 1"
            data-ad-button-location="body"
            href={props.btnLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            { props.btnText }
          </a>
        )
        : (
          <Link
            className={[props.btnClass, 'ad-el-tracked'].join(' ')}
            data-ad-element-type="headline link"
            data-ad-text={props.btnText}
            data-ad-button-id="version 1"
            data-ad-button-location="body"
            to={props.btnLink}
          >
            {props.btnText}
          </Link>
        )}
    </span>

  )
}

export default TextLink
