import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'

const LocatorCallOut = (props) => {
  const partners = useStaticQuery(graphql`
    query {
      allPartnersYaml(filter: {logo_orange: {id: {ne: null}}}) {
        nodes {
          name
          logo_orange {
            childImageSharp {
              gatsbyImageData(
                height: 120
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
                transformOptions: {fit: CONTAIN}
              )
            }
          }
        }
      }
    }
  `)

  return (
    <section id="locatorCallOut" className="pt-3 pb-2 py-md-3 py-lg-4 bg-light">
      <Container className="">
        <Row className="align-items-center g-3 mb-2">
          <Col className="d-none d-md-block" md="5" lg="6">
            <StaticImage
              src="../../images/sections/locator-map.jpg"
              quality={95}
              placeholder="blurred"
              alt="Meati Store Locator"
              layout="constrained"
              className="rounded-4 border border-white"
              imgClassName="rounded-4"
              width={576}
            />
          </Col>
          <Col className="d-flex flex-column align-items-center d-md-block" md="7" lg="6">
            <h2 className="text-primary mb-1 mb-md-2">Meati Store Locator</h2>
            <div className="mb-3 mb-lg-4">
              <ButtonLink btnClass="" btnLink="/where-is-meati" btnText="Search by Zip" />
            </div>
            <StaticImage
              src="../../images/sections/store-locator-boxes.png"
              quality={95}
              placeholder=""
              alt="Meati Boxes"
              layout="constrained"
              className="d-none d-md-block"
              width={624}
            />
            <StaticImage
              src="../../images/sections/locator-map.jpg"
              quality={95}
              placeholder="blurred"
              alt="Meati Store Locator"
              layout="constrained"
              className="rounded-4 border border-white d-md-none"
              imgClassName="rounded-4"
              width={576}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-center flex-wrap px-md-2 px-lg-4" id="locatorLogos">
          {
            partners.allPartnersYaml.nodes.map((node, index) => (
              <div key={`partner-${index}`} className="d-flex align-items-center justify-content-center px-2 py-1 px-md-1 px-lg-3 py-md-0" data-logo={node.name}>
                <GatsbyImage
                  image={getImage(node.logo_orange)}
                  alt={`Meati partner - ${node.name} logo`}
                  className=""
                />
              </div>
            ))
          }
        </div>

      </Container>
    </section>
  )
}

export default LocatorCallOut
